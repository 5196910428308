<template>
  <div>
    <!-- {{formObj.configuration.limitresponse}} -->
    <div class="ma-2">
      <data-iterator :headers="payloadHeaders" :payload="payload"></data-iterator>
    </div>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="primary darken-3">
          <v-btn icon dark @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
          <v-toolbar-title> {{ modelObj._id ? 'Update' : 'Create' }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="saveOrUpdateHandler"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <div v-if="formfields && formfields.length">
          <form-renderer :formId="formId" :modelObj.sync="modelObj"></form-renderer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['formId', 'parentRecordId', 'parentFormId'],
  components: {
    'form-renderer': () => import('@/components/FormRender.vue'),
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  data () {
    return {
      permissionsLevels: [],
      formObj: {},
      modelObj: {
        formid: this.$route.params.id,
        formdata: {},
      },
      dialog: false,
      formfields: [],
      formResults: [],
      payload: {
        // showBackBtn: false,
        create: () => {
          this.modelObj = {
            formid: this.$route.params.id,
            formdata: {}
          }
          this.dialog = true
        },
        items: [],
        showSelect: false,
        config: [{
          title: 'Manage module config',
          icon: 'mdi-cog-box',
          iconSize: 19,
          click: () => this.$router.push(`/forms/actions/${this.moduleid}`)
        }],
        actionsList: []
      }
    }
  },
  mounted () {
    this.getFormProperties()
    this.getFormResponses()
  },
  computed: {
    payloadHeaders () {
      var headers = []
      if (this.formfields.length) {
        this.formfields.map(field => {
          headers.push({
            text: field.label,
            value: `formdata.${field.model}`
          })
        })
      }

      // if (headers.length) {
      //   if (this.permissionsLevels.includes('write')) {
      //     headers.push({
      //       text: 'Actions',
      //       value: 'actions'
      //     })
      //   }
      // }

      headers.push({
        text: 'Actions',
        value: 'actions',
        show: () => this.payload.actionsList.length
      })
      return headers
    },
    moduleid () {
      return this.$route.params.id
    }
  },
  methods: {
    checkpermission () {
      // console.log(this.formObj.configuration)
      // if (this.formObj.configuration && this.formObj.configuration.permissions.length) {
      //   let currentUserPermission = this.formObj.configuration.permissions.find(y => y.role === this.userDetails.role._id)
      //   if (currentUserPermission) {
      //     this.permissionsLevels = currentUserPermission.cando
      //     if (this.permissionsLevels.includes('write')) {
      //       this.payload.create = () => this.dialog = true
      //       this.payload.actionsList.push({
      //         click: (item) => this.editHandler(item._id),
      //         icon: 'mdi-pencil',
      //         color: 'primary'
      //       })
      //     }
      //     if (this.permissionsLevels.includes('delete')) {
      //       this.payload.actionsList.push({
      //         click: (item) => this.deleteHandler(item._id),
      //         icon: 'mdi-delete',
      //         color: 'error'
      //       })
      //     }
      //   }
      // }

      this.payload.actionsList = [{
        click: (item) => this.editHandler(item._id),
        icon: 'mdi-pencil',
        color: 'primary'
      }, {
        click: (item) => this.deleteHandler(item._id),
        icon: 'mdi-delete',
        color: 'error'
      }]
    },
    getFormProperties () {
      this.$_execute('get', `forms/${this.formId}`).then(({ data }) => {
        if (data) {
          this.formObj = data
          this.checkpermission()
          if (data.fields) this.formfields = data.fields
        }
      })
    },
    getFormResponses () {
      let url = `form-result/${this.formId}?parentRecordId=${this.parentRecordId || ''}`
      this.$_execute('get', url)
        .then(({ data } = {}) => {
          this.payload.items = data || []
        })
    },
    saveOrUpdateHandler () {
      const payload = this.$_copy(this.modelObj)
      const url = (payload._id) ? `/form-result/${this.formId}/${payload._id}` : `/form-result/${this.formId}`
      payload.formid = this.formId
      payload.parentRecordId = this.parentRecordId
      payload.parentFormId = this.parentFormId
      this.$_execute((payload._id ? 'put' : 'post'), url, payload)
        .then(() => {
          this.$root.$emit('snackbar', { message: payload._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success' })
          this.dialog = false
          this.modelObj = { formid: this.formId, formdata: {} }
          this.getFormResponses()
        })
    },
    editHandler (id) {
      this.$_execute('get', `form-result/${this.formId}/${id}`)
        .then(({ data } = {}) => {
          this.modelObj = data
          this.dialog = true
        })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: `form-result/${this.formId}`,
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getFormResponses()
        }
      })
    }
  }
}
</script>
